.Paragraph__container {
    width: 100%;

    .Paragraph__title {
        &.Paragraph__large {
            font-size: 20px;
            font-weight: normal;
        }
        &.Paragraph__small {
            font-size: 14px;
            font-weight: bold;
        }
    }
    .Paragraph__message {
        &.Paragraph__messageSpacer {
            margin-top: 14px;
        }
    }

    .Paragraph__spacer {
        height: 5px;
    }

    .Paragraph__disabled {
        color: #ccc;
    }

    &.Paragraph__large {
        margin-top: 40px;
    }

    &.Paragraph__small {
        margin-top: 14px;
    }
}
