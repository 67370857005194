body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
        "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body,
input,
textarea,
label,
div.MuiSelect-select,
li.MuiMenuItem-root {
    font-size: 14px !important;
    color: #666 !important;
}

a:hover {
    cursor: pointer;
}

.global__topMargin20 {
    margin-top: 20px;
}

.global__topMargin40 {
    margin-top: 40px;
}
