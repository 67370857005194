.UserDetails__wrapper {
    display: flex;

    .UserDetails__col {
        &:first-child {
            padding-right: 10px;

            img {
                width: 50px;
                height: 50px;
                border-radius: 50%;

                &.UserDetails__iconPlaceholder {
                    border: 2px solid #333;
                }
            }
        }
        &:nth-child(2) {
            > div:first-child {
                font-weight: bold;
            }
        }
        &:last-child {
            padding-left: 40px;
        }

        display: flex;
        align-items: flex-start;
        align-self: center;
        flex-direction: column;
    }
}
