.TermsOfService_modal {
    background-color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 70%;
    border: none;
    box-shadow: 3px 3px 3px #666;
    outline: none;
    border-radius: 3px;
    padding: 20px;
    overflow: auto;
    max-height: 80%;

    .TermsOfService_modalTitle {
        font-size: 20px;
        color: #2f5396;
    }
}
