.SignUpForm__container {
    display: flex;
    flex-direction: column;
    align-items: center;

    .SignUpForm__row {
        display: block;
        width: 100%;
    }

    textarea,
    .MuiInputBase-root,
    .MuiSelect-root,
    .MuiFormControl-root {
        width: 70%;
        margin-top: 20px;

        > .MuiInputBase-root {
            width: 100%;
        }
    }
    textarea {
        box-sizing: border-box;
        border: 1px solid #ccc;
        border-radius: 3px;
        font-family: inherit;
        outline: none;
        transition: border-bottom-color 1s;
    }
    textarea:not([disabled]):hover {
        border-bottom: 2px solid #999;
    }
    textarea:focus {
        border-bottom: 2px solid #1976d2 !important;
        transition-timing-function: ease-out;
    }
    textarea[disabled] {
        border-style: dotted;
    }

    .MuiInputBase-root:hover:not(.Mui-disabled, .Mui-error):before {
        border-bottom: 2px solid #999;
    }
    .MuiInputBase-root:before {
        border-bottom: 1px solid #ccc;
    }
    .MuiFormLabel-root,
    .MuiFormHelperText-root:not(.Mui-error) {
        color: #ccc !important;
    }

    .SignUpForm_registerMessage {
        margin: 40px 0;
    }

    .SignUpForm__disabled {
        color: #ccc;
    }

    .SignUpForm__hidden {
        display: none;
    }

    .SignUpForm__textareaHelper {
        margin: 3px 0 0 0;
        font-size: 12px;
        width: 70%;
    }
}
