.SelectLanguage__wrapper {
    float: right;

    i {
        &.fa-solid {
            margin-right: 5px;
        }
    }

    .MuiInputBase-root {
        &:before,
        &:after,
        &:hover:not(.Mui-disabled, .Mui-error):before,
        &:hover:not(.Mui-disabled, .Mui-error):after {
            border: 0;
        }
        svg {
            display: none;
        }
    }
    .MuiSelect-select {
        padding-right: 0 !important;
        background-color: transparent !important;
        margin-top: 3px;
    }
}

.SelectLanguage__name {
    padding-left: 5px;
}
